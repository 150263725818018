import { Box, TableBody as MuiTableBody, Skeleton, TableCell, TableRow } from '@mui/material';
import { get } from 'lodash';
import { nanoid } from 'nanoid';

export function TableBody({
  isLoading,
  rows,
  columns,
  checkable,
  renderCheckboxes,
  pagination,
  onCellClick,
}) {
  const getCellData = (data, column) => {
    if (column.render) return column.render(data);

    if (get(data, column.dataIndex) !== undefined || get(data, column.dataIndex) !== null)
      return get(data, column.dataIndex);

    return null;
  };

  return (
    <MuiTableBody>
      {isLoading && (
        <TableRow data-testid="loading">
          <TableCell colSpan={columns.length + (checkable ? 1 : 0) + 1}>
            <Skeleton height={30} />
            <Skeleton height={30} />
            <Skeleton height={30} />
            <Skeleton height={30} />
            <Skeleton height={30} />
            <Skeleton height={30} />
          </TableCell>
        </TableRow>
      )}
      {!isLoading && rows.length === 0 && (
        <TableRow>
          <TableCell colSpan={columns.length + (checkable ? 1 : 0) + 1}>
            <Box className="d-flex-center" sx={{ height: 100 }}>
              No Data
            </Box>
          </TableCell>
        </TableRow>
      )}
      {!isLoading &&
        rows.length > 0 &&
        rows.map((row, rowIndex) => {
          const id = row.id || nanoid();

          return (
            <TableRow key={id}>
              {checkable && typeof renderCheckboxes === 'function' && renderCheckboxes(row)}
              {columns.map((column, colIndex) => {
                if (column.clickable && onCellClick) {
                  return (
                    <TableCell
                      className="cursor-pointer"
                      key={colIndex}
                      align={column.align}
                      onClick={() => onCellClick(row)}
                    >
                      {getCellData(row, column)}
                    </TableCell>
                  );
                }
                return (
                  <TableCell key={colIndex} align={column.align}>
                    {getCellData(row, column)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
    </MuiTableBody>
  );
}
