import { formatDate } from '.';

export const transformVehicleEntries = (vehicle) =>
  Object.keys(vehicle)
    .filter(
      (k) =>
        k !== 'id' &&
        k !== 'manufacturer' &&
        k !== 'shareTo' &&
        k !== 'documents' &&
        k !== 'photos' &&
        k !== 'statementOfConformity' &&
        k !== 'recalls' &&
        k !== 'vehicleEntryNumberId' && 
        k !== 'VehicleEntryNumber'
    )
    .map((key) => {
      let value = vehicle[key];
      if(key === 'entryStatus' && !vehicle[key]) {
        value = 'DRAFT';
      }
      if (key === 'manufacturer') {
        value = vehicle[key].name;
      }

      if (
        key === 'countdown' ||
        key === 'entryDate' ||
        key === 'createdAt' ||
        key === 'updatedAt'
      ) {
        value = formatDate(vehicle[key], 'MM/DD/YYYY');
      }

      if (key === 'manufacturerYear' && typeof vehicle[key] === 'string') {
        value = `20${vehicle[key].slice(-2)}`;
      }

      return {
        key,
        value,
      };
    });

export const isNotDisplayedInVehicleProfile = (entry) =>
  entry.key === 'recalls' ||
  entry.key === 'SOCLink' ||
  entry.key === 'HS7Link' ||
  entry.key === 'NHTSAPackageLink' ||
  entry.key === 'EPAFormLink' ||
  entry.key === 'RILabelLink' ||
  entry.key === 'RIServiceInsuranceLink' ||
  entry.key === 'ProformaInvoiceLink' ||
  entry.key === 'salesOrder' ||
  entry.key === 'manufacturerLabel' ||
  entry.key === 'odometerDisplayImage' ||
  entry.key === 'EPAEmissionLabel' ||
  entry.key === 'IBMRIWarrantyPolicyLink' ||
  entry.key === 'IBMDotBondLink' ||
  entry.key === 'notes';

export const transformVehiclePhotos = (photos, vin) =>
  photos.map((photo) => {
    const { url, category } = photo;
    const photoId = photo.id;

    return {
      // category: category.replace(/_/g, ' '),
      category,
      vin: vin,
      url: url,
      photoId: photoId,
      uploading: false,
    };
  });

export const isUSMCAApplicable = (vin, year) => {
    if (!vin || !year) return false;
    return (vin.startsWith('2') || vin.startsWith('3')) && year >= 2020;
};
