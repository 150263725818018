import { useMutation } from '@tanstack/react-query';
import { VehicleApi } from 'api';
import { DOCUMENT_CATEGORIES_USMCA, VEHICLE_STATUSES } from 'constants/vehicle';
import { useFormik } from 'formik';
import moment from 'moment';
import { AuthContext } from 'providers/authProvider';
import { useContext, useEffect, useState } from 'react';

import { NOTIFICATION_TYPE } from 'components';

import { getDefaultFromType } from './StatementConformity/utils';
import { DOT_BOX_03, EPA_CODE_FF } from './VehicleForm.constants';
import { validationSchema, validationVinSchema } from './VehicleForm.validator';
import { useGetVehicleDocuments } from 'features/documents/useGetVehicleDocuments';
import { sortItem } from 'utilities/dragDrop';

/**
 * Custom hook for Vehicle Form
 *
 * @param {object} vehicle
 * @param {string} vin
 * @param {Function} notificationCallback
 * @returns {[import('formik').FormikProps<any>, boolean, (type?: string) => Promise<{status: boolean, data: any,  message?: string}>]}
 */
const useVehicleForm = (vehicle, vin = '', notificationCallback = () => {}) => {
  const { user } = useContext(AuthContext);

  const initialValues = {
    // General Information
    vehicle_id: vehicle?.id || null,
    vin: vehicle?.vin || vin,
    manufacturer_code: vehicle?.manufacturer_code || null,
    year: vehicle?.year || '',
    make: vehicle?.make || '',
    model: vehicle?.model || '',
    entryDate: vehicle?.entryDate
      ? moment(vehicle.entryDate).utc().toISOString()
      : moment.utc().toISOString(),
    USSellingPrice: vehicle?.USSellingPrice || '',
    countryOfOrigin: vehicle?.countryOfOrigin || '',
    salesOrder: vehicle?.salesOrder || null,
    miles: vehicle?.miles || '',
    KM: vehicle?.KM || '',
    odometerDisplay: vehicle?.odometerDisplay || 'miles',
    odometerDisplayImage: vehicle?.odometerDisplayImage || null,
    initialCertification: !!vehicle?.initialCertification,
    exporter_code: vehicle?.exporter_code || null,
    buyer_code: vehicle?.buyer_code || null,
    buyer_related: vehicle?.buyer_related || false,
    consignee_code: vehicle?.consignee_code || null,
    conformity_location_code: vehicle?.conformity_location_code || null,

    // Manufacturer Information
    manufacturerLabel: vehicle?.manufacturerLabel || null,
    manufacturerMonth: vehicle?.manufacturerMonth || '',
    manufacturerYear: vehicle?.manufacturerYear || '',
    weightOnLabel: vehicle?.weightOnLabel || 'LBS',
    GVWRLBS: vehicle?.GVWRLBS || '',
    GVWRKGS: vehicle?.GVWRKGS || '',
    frontGAWRLBS: vehicle?.frontGAWRLBS || '',
    frontGAWRKGS: vehicle?.frontGAWRKGS || '',
    rearGAWRLBS: vehicle?.rearGAWRLBS || '',
    rearGAWRKGS: vehicle?.rearGAWRKGS || '',

    // Tire and Loading Information
    frontTireSize: vehicle?.frontTireSize || '',
    frontRimSize: vehicle?.frontRimSize || '',
    frontColdPSI: vehicle?.frontColdPSI || '',
    frontAxleType: vehicle?.frontAxleType || '',
    rearTireSize: vehicle?.rearTireSize || '',
    rearRimSize: vehicle?.rearRimSize || '',
    rearColdPSI: vehicle?.rearColdPSI || '',
    rearAxleType: vehicle?.rearAxleType || '',
    '2ndTireSize': vehicle?.['2ndTireSize'] || '',
    '2ndRimSize': vehicle?.['2ndRimSize'] || '',
    '2ndColdPSI': vehicle?.['2ndColdPSI'] || '',
    '2ndAxleType': vehicle?.['2ndAxleType'] || '',
    '3rdTireSize': vehicle?.['3rdTireSize'] || '',
    '3rdRimSize': vehicle?.['3rdRimSize'] || '',
    '3rdColdPSI': vehicle?.['3rdColdPSI'] || '',
    '3rdAxleType': vehicle?.['3rdAxleType'] || '',

    // Transporter Information
    transportationMode: vehicle?.transportationMode || 'Truck',
    transporter_code: vehicle?.transporter_code || null,
    SCACCode: vehicle?.SCACCode || '',
    PAPSCode: vehicle?.PAPSCode || '',

    // Customer Information
    notes: vehicle?.notes || '',

    // EPA Emission Information
    EPACode: vehicle?.EPACode || EPA_CODE_FF,
    EPAEmissionLabel: vehicle?.EPAEmissionLabel || '',

    // DOT information
    dotBox: vehicle?.dotBox || DOT_BOX_03,
    engineFamily: vehicle?.engineFamily || '',
    vehicleType: vehicle?.vehicleType || '',
    VSACode: vehicle?.VSACode || '',

    // Broker Information
    warrantyProvider: user.warrantyProvider?.companyName || '',
    bondProvider: user.bondProvider?.companyName || '',
    country: vehicle?.country || 'Canada',
    theftProtection: !!vehicle?.theftProtection,
    portOfArrival: user.lastUsedPort?.id || '',
    tariff_code: vehicle?.tariff_code || null,

    // Statement of conformity
    control_and_displays: vehicle?.statementOfConformity?.control_and_displays || '',
    transmission_Shift_sequence: vehicle?.statementOfConformity?.transmission_Shift_sequence || '',
    windshield_defrosting_defogging:
      vehicle?.statementOfConformity?.windshield_defrosting_defogging || '',
    windshield_wiping_washing: vehicle?.statementOfConformity?.windshield_wiping_washing || '',
    hydraulic_and_electric_brake_system:
      vehicle?.statementOfConformity?.hydraulic_and_electric_brake_system || '',
    brake_hoses: vehicle?.statementOfConformity?.brake_hoses || '',
    lamps_reflective_devices: vehicle?.statementOfConformity?.lamps_reflective_devices || '',
    new_pneumatic_tires: vehicle?.statementOfConformity?.new_pneumatic_tires || '',
    tire_selection_and_rims: vehicle?.statementOfConformity?.tire_selection_and_rims || '',
    air_brake_systems: vehicle?.statementOfConformity?.air_brake_systems || '',
    motorcycle_brake_systems: vehicle?.statementOfConformity?.motorcycle_brake_systems || '',
    motorcycle_controls_and_displays:
      vehicle?.statementOfConformity?.motorcycle_controls_and_displays || '',
    accelerator_control_systems: vehicle?.statementOfConformity?.accelerator_control_systems || '',
    electronic_stability_control:
      vehicle?.statementOfConformity?.electronic_stability_control || '',
    non_pneumatic_tires: vehicle?.statementOfConformity?.non_pneumatic_tires || '',
    school_bus_pedestrian_safety:
      vehicle?.statementOfConformity?.school_bus_pedestrian_safety || '',
    light_vehicle_brake_system: vehicle?.statementOfConformity?.light_vehicle_brake_system || '',
    tire_pressure_monitoring_system:
      vehicle?.statementOfConformity?.tire_pressure_monitoring_system || '',
    new_pneumatic_radial_tires: vehicle?.statementOfConformity?.new_pneumatic_radial_tires || '',
    occupant_protection_interior_impact:
      vehicle?.statementOfConformity?.occupant_protection_interior_impact || '',
    head_restraints: vehicle?.statementOfConformity?.head_restraints || '',
    impact_protection_from_steering:
      vehicle?.statementOfConformity?.impact_protection_from_steering || '',
    steering_control_rearward: vehicle?.statementOfConformity?.steering_control_rearward || '',
    glazing_materials: vehicle?.statementOfConformity?.glazing_materials || '',
    door_locks_and_retention_components:
      vehicle?.statementOfConformity?.door_locks_and_retention_components || '',
    seating_systems: vehicle?.statementOfConformity?.seating_systems || '',
    occupant_crash_protection: vehicle?.statementOfConformity?.occupant_crash_protection || '',
    seat_belt_assemblies: vehicle?.statementOfConformity?.seat_belt_assemblies || '',
    seat_belt_assemblies_anchorages:
      vehicle?.statementOfConformity?.seat_belt_assemblies_anchorages || '',
    windshield_mounting: vehicle?.statementOfConformity?.windshield_mounting || '',
    child_restraint_systems: vehicle?.statementOfConformity?.child_restraint_systems || '',
    side_impact_protection: vehicle?.statementOfConformity?.side_impact_protection || '',
    roof_crush_resistance: vehicle?.statementOfConformity?.roof_crush_resistance || '',
    bus_exists_window_retention: vehicle?.statementOfConformity?.bus_exists_window_retention || '',
    windshield_zone_intrusion: vehicle?.statementOfConformity?.windshield_zone_intrusion || '',
    school_bus_rollover_protection:
      vehicle?.statementOfConformity?.school_bus_rollover_protection || '',
    school_bus_joint_strength: vehicle?.statementOfConformity?.school_bus_joint_strength || '',
    school_bus_seating_crash_protection:
      vehicle?.statementOfConformity?.school_bus_seating_crash_protection || '',
    rear_impact_guards: vehicle?.statementOfConformity?.rear_impact_guards || '',
    child_restraint_anchorage_system:
      vehicle?.statementOfConformity?.child_restraint_anchorage_system || '',
    ejection_mitigation: vehicle?.statementOfConformity?.ejection_mitigation || '',
    fuel_system_integrity: vehicle?.statementOfConformity?.fuel_system_integrity || '',
    flammability_of_interior_materials:
      vehicle?.statementOfConformity?.flammability_of_interior_materials || '',
    fuel_system_integrity_of_cng_vehicle:
      vehicle?.statementOfConformity?.fuel_system_integrity_of_cng_vehicle || '',
    cng_fuel_container_integrity:
      vehicle?.statementOfConformity?.cng_fuel_container_integrity || '',
    electric_powered_vehicles: vehicle?.statementOfConformity?.electric_powered_vehicles || '',
    interior_trunk_release: vehicle?.statementOfConformity?.interior_trunk_release || '',
    platform_lift_installations: vehicle?.statementOfConformity?.platform_lift_installations || '',
    low_speed_vehicles: vehicle?.statementOfConformity?.low_speed_vehicles || '',
    vin_requirements: vehicle?.statementOfConformity?.vin_requirements || '',
    certification: vehicle?.statementOfConformity?.certification || '',
    bumper_standard: vehicle?.statementOfConformity?.bumper_standard || '',
    rearview_mirros: vehicle?.statementOfConformity?.rearview_mirros || '',
    hoot_latch_system: vehicle?.statementOfConformity?.hoot_latch_system || '',
    theft_protection: vehicle?.statementOfConformity?.theft_protection || '',
    motor_vehicle_brake_fluids: vehicle?.statementOfConformity?.motor_vehicle_brake_fluids || '',
    power_operated_window_systems:
      vehicle?.statementOfConformity?.power_operated_window_systems || '',
    new_pneumatic_tires2: vehicle?.statementOfConformity?.new_pneumatic_tires2 || '',
    tire_selection_and_rims2: vehicle?.statementOfConformity?.tire_selection_and_rims2 || '',
    rear_impact_protection: vehicle?.statementOfConformity?.rear_impact_protection || '',
    term_1: vehicle?.statementOfConformity?.term_1 || 0,
    term_2: vehicle?.statementOfConformity?.term_2 || 0,
    term_3: vehicle?.statementOfConformity?.term_3 || 0,
    term_4: vehicle?.statementOfConformity?.term_4 || 0,
    status: VEHICLE_STATUSES[0],
  };

  const {data: documents = []} = useGetVehicleDocuments(initialValues.vehicle_id);

  const handleSubmit = async (type = VEHICLE_STATUSES[0], cb) => {
    try {
      const usmcaDocuments = documents.filter((d) => DOCUMENT_CATEGORIES_USMCA.includes(d.category.name)).sort(sortItem);

      if ((form.values.year !== initialValues.year || form.values.vin !== initialValues.vin) && usmcaDocuments.length > 0) {
        notificationCallback(NOTIFICATION_TYPE.DANGER, 'Remove USMCA documents before changing VIN or Year');
        form.setSubmitting(false);
        return;
      }

      form.setSubmitting(true);
      if (type === VEHICLE_STATUSES[1]) {
        const validationResult = await validationSchema
          .validate(form.values, { abortEarly: false })
          .catch((err) => err);
        if (validationResult.inner !== undefined) {
          let formErrors = {};
          let formTouched = {};
          for (let i = 0; i < validationResult.inner.length; i++) {
            formTouched = { ...formTouched, [validationResult.inner[i].path]: true };
            formErrors = {
              ...formErrors,
              [validationResult.inner[i].path]: validationResult.inner[i].message,
            };
          }
          form.setTouched(formTouched);
          form.setErrors(formErrors);
          form.setSubmitting(false);
          return;
        }
      }
      let data = form.values;
      if (form.values.vehicleType) {
        let formTouched = {};
        const soc = getDefaultFromType(form.values.vehicleType);
        const socKeys = Object.keys(soc);
        for (let i = 0; i < socKeys.length; i++) {
          formTouched = { ...formTouched, [socKeys[i]]: true };
        }
        data = { ...data, ...soc };
      }
      await form.submitForm();
      mutation.mutate(data, {
        onSuccess: async (data) => {
          await form.setFieldValue('vehicle_id', data.data.vehicle.id);
          notificationCallback(
            NOTIFICATION_TYPE.SUCCESS,
            `Save vehicle with status ${data.data.vehicle.status}`
          );
          form.setSubmitting(false);
          cb(data.status);
          return { status: 'success', data: data.data };
        },
      });
    } catch (e) {
      console.trace(e);
    }
  };

  const form = useFormik({
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: validationVinSchema,
    initialValues,
    onSubmit: () => {},
  });

  const mutation = useMutation({
    mutationFn: (data) => {
      return VehicleApi.create(data);
    },
    onError: async (err) => {
      form.setSubmitting(false);
      if (err?.type === 'body') {
        notificationCallback(NOTIFICATION_TYPE.DANGER, err.message.details[0].message);
      } else {
        notificationCallback(NOTIFICATION_TYPE.DANGER, err.message);
      }
      return { status: 'failed', message: 'error creating vehicle' };
    },
  });
  const [buttonDisable, setButtonDisable] = useState(true);
  useEffect(() => {
    validationSchema
      .validate(form.values, { abortEarly: false })
      .then((res) => {
        setButtonDisable(false);
      })
      .catch((e) => {
        console.log(e.errors);
        setButtonDisable(true);
      });
  }, [form.values]);
  return [form, form.isSubmitting, handleSubmit, mutation.data, buttonDisable];
};

export default useVehicleForm;
