import { DownloadFileApi } from 'api';
import { useRenderPdfToCanvas } from 'hooks/useRenderPdfToCanvas';
import React, { useState } from 'react';
import { Move, Trash, ZoomIn } from 'react-feather';

import { ConfirmModal, NOTIFICATION_TYPE, Notification } from 'components';
import PDFViewerModal from 'components/PDFViewerModal';
import UnstyledButton from 'components/UnstyledButton';
import { useDeleteVehicleDocument } from 'features/documents/useDeleteVehicleDocument';

import './style.scss';
import { DOCUMENT_CATEGORIES_USMCA } from 'constants/vehicle';

const DocumentItem = ({ item, dragHandleProps }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const { mutateAsync: deleteVehicleDocument } = useDeleteVehicleDocument();

  const { isLoading, canvasRef } = useRenderPdfToCanvas({
    file: item.file,
    fetchFn: DownloadFileApi.getFile,
  });

  const handleShowConfirm = () => {
    setShowConfirm(true);
  };

  const handleDelete = () => {
    deleteVehicleDocument(item.id)
      .then(() => {
        Notification(NOTIFICATION_TYPE.SUCCESS, 'Document deleted successfully');
      })
      .catch((error) => {
        Notification(NOTIFICATION_TYPE.DANGER, error.message);
      })
      .finally(() => {
        setShowConfirm(false);
      });
  };

  return (
    <>
      <div className="document-item">
        <h5 className="mb-4">{item.category.name}</h5>
        <div className="document-item-wrapper">
          {isLoading && (
            <div className="loading">
              <span className="text-grey">Loading ...</span>
            </div>
          )}
          <div {...dragHandleProps} className="handle">
            <Move className="btn-icon" />
          </div>
          <canvas ref={canvasRef} />
          {!isLoading && (
            <div className="document-actions">
              <UnstyledButton
                className="document-actions__btn"
                aria-label="open preview"
                onClick={() => setShowPreview(true)}
              >
                <ZoomIn className="btn-icon" />
              </UnstyledButton>
              {(item.category.canDelete || DOCUMENT_CATEGORIES_USMCA.includes(item.category.name)) && (
                <UnstyledButton
                  className="document-actions__btn"
                  aria-label="delete"
                  onClick={handleShowConfirm}
                >
                  <Trash className="btn-icon" />
                </UnstyledButton>
              )}
            </div>
          )}
        </div>
      </div>
      <PDFViewerModal file={item.file} open={showPreview} setOpen={setShowPreview} />
      <ConfirmModal
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        title="Delete Document"
        content="Are you sure you want to delete this document?"
        onConfirm={handleDelete}
      />
    </>
  );
};

export default DocumentItem;
