import DeleteIcon from '@mui/icons-material/Delete';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { DocumentCategoryApi, ModalApi } from 'api';
import moment from 'moment';
import { AuthContext } from 'providers/authProvider';
import useVinScanner from 'providers/vinScannerProvider';
import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { isRegisteredImporter } from 'services/auth';
import { convertToKM, convertToMiles } from 'utilities';
import { convertCountryToISO2 } from 'utilities/country';

import { Button, Input, InputFile, NOTIFICATION_TYPE, Notification, TextArea } from 'components';
import CustomDatePicker from 'components/CustomDatePicker';
import ImagePreview from 'components/ImagePreview/ImagePreview';
import { ConformityLocationSelect } from 'features/conformity-locations/components/ConformityLocationSelect';

import VehicleRecalls from '../../VehicleProfile/VehicleRecalls';
import { vehicleTypeOptions } from '../DotInfo';
import { VEHICLE_TYPES } from '../VehicleForm.constants';
import AddManufacturerModal from '../components/modals/AddManufacturerModal';
import useCheckVin from '../useCheckVin';
import useDeletePhoto from '../useDeletePhoto';
import useUploadImage from '../useUploadImage';
import { BuyerInfo } from './BuyerInfo';
import { ConsigneeInfo } from './ConsigneeInfo';
import { ExporterInfo } from './ExporterInfo';
import { ManufacturerInfo } from './ManufacturerInfo';
import './styles.scss';
import { isUSMCAApplicable } from 'utilities/vehicle';
import { useUploadVehicleDocument } from 'features/documents/useUploadVehicleDocument';
import { DOCUMENT_CATEGORIES_USMCA } from 'constants/vehicle';

const GeneralInfo = forwardRef(({ form, lastSaved, setLastSaved, vehicle }, ref) => {
  const { vin: scannedVin, setData: setScannedVin } = useVinScanner();

  const [vin, setVin] = useState(scannedVin);

  const [showManufacturerModal, setShowManufacturerModal] = useState(false);
  const [images, setImages] = useState({
    manufacturerLabel: vehicle?.manufacturerLabel,
    salesOrder: vehicle?.salesOrder,
    odometerDisplayImage: vehicle?.odometerDisplayImage,
  });
  const [manufacturer, setManufacturer] = useState('');
  const [isOverride, setIsOverride] = useState(false);

  const [showUsmcaField, setShowUsmcaField] = useState(false);

  const { mutateAsync: uploadVehicleDocument } = useUploadVehicleDocument();

  const { user } = useContext(AuthContext);

  const { data, isLoading, isFetching } = useCheckVin(vin, {
    onError: (error) => {
      Notification(NOTIFICATION_TYPE.DANGER, error.message);
    },
  });
  const [recalls, setRecalls] = useState(vehicle?.recalls || []);

  const handleProcessData = async (data) => {
    if (!data) return;
    if (!data.vehicleData) return;
    if (data.recallData) setRecalls(data.recallData.results);
    const { make, model, year, vehicleType, manufacturer, countryOfOrigin } = data.vehicleData;

    await form.setFieldValue('make', make);
    await form.setFieldValue('model', model);
    await form.setFieldValue('year', year);
    const checkVehicleType = VEHICLE_TYPES.find((item) => item === vehicleType);
    if (checkVehicleType) {
      await form.setFieldValue('vehicleType', setVehicleType(checkVehicleType));
    }
    await form.setFieldValue('vehicleType', setVehicleType(vehicleType));
    await form.setFieldValue(
      'countryOfOrigin',
      countryOfOrigin ? convertCountryToISO2(countryOfOrigin) : ''
    );
    if (manufacturer) {
      setManufacturer(manufacturer);
      const res = await ModalApi.create('Manufacturer', { name: manufacturer });

      setLastSaved({
        Manufacturer: res.data,
      });
    }

    // Reset scanned vin
    if (vin) {
      setScannedVin('');
    }
  };

  React.useEffect(() => {
    if (isUSMCAApplicable(form.values.vin, form.values.year)) {
      setShowUsmcaField(true);
    } else {
      setShowUsmcaField(false);
    }
  }, [form.values.vin, form.values.year]);

  React.useEffect(() => {
    !!vin && handleProcessData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, vin]);

  const handleVinSearch = () => {
    if (!form.values.vin) {
      return;
    }

    setVin(form.values.vin);
  };

  useImperativeHandle(ref, () => ({
    checkVinFromScanner(vin) {
      setVin(vin);
    },
  }));

  const {
    handleDeletePhoto,
    isLoading: deleteIsLoading,
    fieldName: deleteFieldName,
  } = useDeletePhoto(form.values.vehicle_id, setImages);

  const {
    handleUploadImage,
    isLoading: uploadIsLoading,
    fieldName,
  } = useUploadImage(images, form.values.vin, form.values.vehicle_id, setImages);

  const handleFileUpload = async (file,categoryName) => {
    const category = await DocumentCategoryApi.getByName(categoryName);
    const fd = new FormData();
    fd.append('vehicleId', vehicle.id);
    fd.append('file', file);
    fd.append('categoryId', category.id);
    try {
      await uploadVehicleDocument(fd);
      Notification(NOTIFICATION_TYPE.SUCCESS, 'Document uploaded successfully');
    } catch (err) {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    }
  };
  return (
    <>
      <div className="row" id="vin">
        <div className="col-md-6 mb-3 d-flex align-items-end justify-content-between">
          <Input
            name="vin"
            className={'w-100'}
            label="VIN *"
            {...form.getFieldProps('vin')}
            error={form.errors.vin && form.touched.vin ? form.errors.vin : ''}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
                handleVinSearch();
              }
            }}
          />

          <Button
            className="ms-4"
            onClick={handleVinSearch}
            loading={isLoading || isFetching}
            disabled={!form.values.vin || isLoading || isFetching}
          >
            Search
          </Button>
        </div>

        <div className="col-md-6 mb-3" id="year">
          <Input
            name="year"
            label="Year *"
            {...form.getFieldProps('year')}
            error={form.errors.year && form.touched.year ? form.errors.year : ''}
          />
        </div>
      </div>

      <div className="row" id="make">
        <div className="col-md-6 mb-3">
          <Input
            name="make"
            label="Make *"
            {...form.getFieldProps('make')}
            error={form.errors.make && form.touched.make ? form.errors.make : ''}
          />
        </div>
        <div className="col-md-6 mb-3" id="model">
          <Input
            name="model"
            label="Model *"
            {...form.getFieldProps('model')}
            error={form.errors.model && form.touched.model ? form.errors.model : ''}
          />
        </div>
      </div>

      <div className="row mb-3" id="manufacturer_code">
        <ManufacturerInfo
          form={form}
          manufacturer={manufacturer}
          lastSaved={lastSaved}
          setLastSaved={setLastSaved}
        />
        <div className="col-md-6" name="entryDate" id="entryDate">
          <CustomDatePicker
            id="entry-date"
            label="Entry Date *"
            dark={true}
            dateFormat="MM/DD/YYYY"
            timeFormat={false}
            value={moment.utc(form.values.entryDate)}
            onChange={(d) => {
              form.setFieldValue('entryDate', d.toISOString());
            }}
            error={form.errors.entryDate && form.touched.entryDate ? form.errors.entryDate : ''}
            closeOnSelect={true}
          />
        </div>
      </div>

      <hr />

      <div className="flex bg-dark-light py-3 mb-5">
        <div className="pb-1 px-2 vehicle-profile-column-header d-flex align-items-center justify-content-between">
          <h3>Recalls</h3>
        </div>
        <div className={'py-3'}>
          <VehicleRecalls
            vehicleRecalls={recalls?.map((vr) => ({ ...vr, isChecked: false })) ?? []}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3" id="USSellingPrice">
          <Input
            type={'number'}
            name="USSelingPrice"
            label="US Selling Price *"
            {...form.getFieldProps('USSellingPrice')}
            error={
              form.errors.USSellingPrice && form.touched.USSellingPrice
                ? form.errors.USSellingPrice
                : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3" id="countryOfOrigin">
          <Input
              label="Country of Origin *"
              {...form.getFieldProps('countryOfOrigin')}
              error={
                form.errors.countryOfOrigin && form.touched.countryOfOrigin
                    ? form.errors.countryOfOrigin
                    : ''
              }
          />
        </div>
      </div>

       {showUsmcaField && <div className="row mb-3">
        <div className="col-md-6">
         <label htmlFor="usmca_cert" className="mb-1">
          USMCA Certificate of Origin
         </label>
         <div>
          <input
              className={`form-control `}
              type="file"
              onChange={(e) => handleFileUpload(e.target.files[0], DOCUMENT_CATEGORIES_USMCA[1])}
          />
         </div>
        </div>

        <div className="col-md-6 ">
         <label htmlFor="usmca_invoice" className="mb-1">
          USMCA Invoice
         </label>
         <div>
          <input
              className={`form-control `}
              type="file"
              onChange={(e) => handleFileUpload(e.target.files[0], DOCUMENT_CATEGORIES_USMCA[0])}
          />
         </div>
        </div>
       </div>}

       <div className="col-12 mb-3" id="salesOrder">
        <label htmlFor="salesOrder" className="mb-1">
          Sales Order
        </label>
        <div className="row">
          <div className="col-md-6 ">
            <InputFile
              handleUploadImage={(e) => handleUploadImage('salesOrder', e.target.files[0])}
              error={
                form.errors.salesOrder && form.touched.salesOrder ? form.errors.salesOrder : ''
              }
            />
          </div>
          <div className="col-md-6 mb-3 image-container">
            {fieldName === 'salesOrder' && uploadIsLoading && (
              <HourglassBottomIcon className="text-secondary" />
            )}
            <ImagePreview src={images.salesOrder} alt="Preview of Sales Order" />
            {images.salesOrder && (
              <div
                className=" text-danger p-1 bg-light image-close"
                onClick={() => handleDeletePhoto('SalesOrder', 'salesOrder')}
              >
                {deleteIsLoading && deleteFieldName === 'salesOrder' ? (
                  <HourglassBottomIcon className="text-secondary" />
                ) : (
                  <DeleteIcon className="text-danger" />
                )}
              </div>
            )}
          </div>
        </div>
       </div>

      <h6 className="mb-2 fw-bold">ODOMETER</h6>

      <div className="row">
        <div className="col-md-6 mb-4 d-flex align-items-end justify-content-between" id="miles">
          <Input
            className={'w-100'}
            type="number"
            label="Miles *"
            {...form.getFieldProps('miles')}
            onChange={(e) => {
              form.setFieldValue('miles', Math.round(e.target.value).toString());
              if (!isOverride) {
                form.setFieldValue('KM', Math.round(convertToKM(e.target.value)).toString());
              }
            }}
            error={form.errors.miles && form.touched.miles ? form.errors.miles : ''}
          />

          <Button
            className="ms-4"
            variant={!isOverride ? 'dark' : ''}
            onClick={() => setIsOverride(!isOverride)}
          >
            Override
          </Button>
        </div>

        <div className="col-md-6 mb-4" id="KM">
          <Input
            label="KM *"
            type="number"
            {...form.getFieldProps('KM')}
            onChange={(e) => {
              form.setFieldValue('KM', Math.round(e.target.value).toString());
              if (!isOverride) {
                form.setFieldValue('miles', Math.round(convertToMiles(e.target.value)).toString());
              }
            }}
            error={form.errors.KM && form.touched.KM ? form.errors.KM : ''}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <label id="odometer-display-label" className="mb-1">
            Odometer Display *
          </label>
        </div>
        <div className="col-md-6 mb-3">
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="odometer-display-label"
                {...form?.getFieldProps('odometerDisplay')}
                onChange={(e) => {
                  form.setFieldValue('odometerDisplay', e.target.value);
                }}
              >
                <FormControlLabel value="miles" control={<Radio />} label="Miles" />
                <FormControlLabel value="kms" control={<Radio />} label="Kms" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-end" id="buyer_code">
        <BuyerInfo form={form} lastSaved={lastSaved} setLastSaved={setLastSaved} />
      </div>

      <div className="row mb-3 align-items-end" id="seller_code">
        <ExporterInfo form={form} lastSaved={lastSaved} setLastSaved={setLastSaved} />
      </div>

      <div className="row mb-3">
        <div className="col-md-6 mb-3">
          <div>
            <FormControl>
              <RadioGroup
                // aria-labelledby="buyer-related-label"
                {...form?.getFieldProps('buyer_related')}
                onChange={(e) => {
                  form.setFieldValue('buyer_related', e.target.value === 'true');
                }}
              >
                <FormControlLabel value={false} control={<Radio />} label="Not Related" />
                <FormControlLabel value={true} control={<Radio />} label="Related" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-end" id="consignee_code">
        <ConsigneeInfo form={form} lastSaved={lastSaved} setLastSaved={setLastSaved} />
      </div>

      <div className="row mb-4" id="conformity_location_code">
        <div className="col-7 col-md-6">
          {isRegisteredImporter(user) ? (
            <ConformityLocationSelect
              label="Address where vehicle was conformed *"
              value={form.values.conformity_location_code}
              onChange={(v) => {
                form.setFieldValue('conformity_location_code', v.value);
              }}
              error={
                form.errors.conformity_location_code && form.touched.conformity_location_code
                  ? form.errors.conformity_location_code
                  : ''
              }
            />
          ) : (
            <TextArea
              label="Address where vehicle was conformed *"
              disabled
              value={
                user.importer?.conformityLocation
                  ? `${user.importer.conformityLocation.conformedStreet}, ${user.importer.conformityLocation.conformedCity}, ${user.importer.conformityLocation.conformedState}, ${user.importer.conformityLocation.conformedZip}, ${user.importer.conformityLocation.conformedCountry}`
                  : ''
              }
              error={
                form.errors.conformity_location_code && form.touched.conformity_location_code
                  ? form.errors.conformity_location_code
                  : ''
              }
            />
          )}
        </div>
        <div className="col-7 col-md-6" id="conformity_location_code">
          {isRegisteredImporter(user) ? (
            <ConformityLocationSelect
              label="Address where vehicle can be inspected *"
              value={form.values.conformity_location_code}
              disabled
              error={
                form.errors.conformity_location_code && form.touched.conformity_location_code
                  ? form.errors.conformity_location_code
                  : ''
              }
            />
          ) : (
            <TextArea
              label="Address where vehicle can be inspected *"
              disabled
              value={
                user.importer?.conformityLocation
                  ? `${user.importer.conformityLocation.inspectedStreet}, ${user.importer.conformityLocation.inspectedCity}, ${user.importer.conformityLocation.inspectedState}, ${user.importer.conformityLocation.inspectedZip}, ${user.importer.conformityLocation.inspectedCountry}`
                  : ''
              }
              error={
                form.errors.conformity_location_code && form.touched.conformity_location_code
                  ? form.errors.conformity_location_code
                  : ''
              }
            />
          )}
        </div>
      </div>

      <div className="col-12">
        <label htmlFor="manufacturerLabel" className="mb-1">
          Photos of Manufacturer's Label
        </label>
        <div className="row">
          <div className="col-md-6 mb-3">
            <input
              className="form-control"
              type="file"
              id="manufacturerLabel"
              onChange={(e) => handleUploadImage('manufacturerLabel', e.target.files[0])}
            />
          </div>
          <div className="col-md-6 mb-3">
            {fieldName === 'manufacturerLabel' && uploadIsLoading && (
              <HourglassBottomIcon className="text-secondary" />
            )}
            <ImagePreview src={images.manufacturerLabel} alt="Preview of manufacturer label" />
            {images.manufacturerLabel && (
              <div
                className=" text-danger p-1 bg-light image-close"
                onClick={() => handleDeletePhoto('ManufacturerLabel', 'manufacturerLabel')}
              >
                {deleteIsLoading && deleteFieldName === 'manufacturerLabel' ? (
                  <HourglassBottomIcon className="text-secondary" />
                ) : (
                  <DeleteIcon className="text-danger" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="col-12">
        <label htmlFor="odometerDisplayImage" className="mb-1">
          Odometer Display (Mileage)
        </label>
        <div className="row">
          <div className="col-md-6 mb-3">
            <input
              className="form-control"
              type="file"
              id="odometerDisplayImage"
              onChange={(e) => handleUploadImage('odometerDisplayImage', e.target.files[0])}
            />
          </div>
          <div className="col-md-6 mb-3">
            {fieldName === 'odometerDisplayImage' && uploadIsLoading && (
              <HourglassBottomIcon className="text-secondary" />
            )}
            <ImagePreview src={images.odometerDisplayImage} alt="Preview of odometer display" />
            {images.odometerDisplayImage && (
              <div
                className=" text-danger p-1 bg-light image-close"
                onClick={() => handleDeletePhoto('OdometerDisplayImage', 'odometerDisplayImage')}
              >
                {deleteIsLoading && deleteFieldName === 'odometerDisplayImage' ? (
                  <HourglassBottomIcon className="text-secondary" />
                ) : (
                  <DeleteIcon className="text-danger" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <AddManufacturerModal
        show={showManufacturerModal}
        handleClose={() => setShowManufacturerModal(false)}
        name="Manufacturer"
        setLastSaved={setLastSaved}
        lastSaved={lastSaved}
      />
    </>
  );
});

function setVehicleType(type) {
  const vehicleType = vehicleTypeOptions.find(
    (item) => item.value?.toLowerCase() === type?.toLowerCase()
  )?.value;
  return vehicleType
    ? vehicleType.charAt(0).toUpperCase() + vehicleType?.toLowerCase().slice(1)
    : '';
}

export default GeneralInfo;
