import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React from 'react';

const TableHeader = ({
  columns,
  order,
  orderBy,
  onSortChange,
  checkable,
  allChecked,
  onCheckAllChange,
}) => {
  return (
    <TableHead>
      <TableRow>
        {checkable && (
          <TableCell padding="checkbox" sx={{ border: 'none' }}>
            <Checkbox
              color="primary"
              checked={allChecked}
              onChange={onCheckAllChange}
              sx={{ color: (theme) => theme.colors.white }}
              data-testid="header-checkbox"
            />
          </TableCell>
        )}
        {columns.map((column, index) => {
          return (
            <TableCell
              key={index}
              align={column.align}
              sortDirection={orderBy === column.dataIndex ? order : false}
            >
              {column.sortable ? (
                <TableSortLabel
                  active={orderBy === column.dataIndex}
                  direction={orderBy === column.dataIndex ? order : 'asc'}
                  onClick={() => onSortChange(column.dataIndex)}
                  data-testid={`sort-label-${index}`}
                  sx={{
                    color: (theme) => theme.colors.white,
                    py: 3,

                    '&:hover': {
                      color: 'rgba(255, 255, 255, 0.7)',
                    },

                    '&.Mui-active': {
                      color: (theme) => theme.colors.white,

                      '& .MuiTableSortLabel-icon': {
                        color: 'rgba(255, 255, 255, 0.6)',
                      },
                    },
                  }}
                >
                  {column.title}
                </TableSortLabel>
              ) : (
                column.title
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
