import classnames from 'classnames';
import { useRenderPdfToCanvas } from 'hooks/useRenderPdfToCanvas';
import React, { useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trash } from 'react-feather';

import {
  Button,
  ConfirmModal,
  CustomSelect,
  IconButton,
  NOTIFICATION_TYPE,
  Notification,
} from 'components';
import { useCreateDocumentCategory } from 'features/documents/useCreateDocumentCategory';
import { useDeleteDocumentCategory } from 'features/documents/useDeleteDocumentCategory';
import { useUploadVehicleDocument } from 'features/documents/useUploadVehicleDocument';

import './style.scss';
import { DOCUMENT_CATEGORIES_USMCA } from 'constants/vehicle';
import { isUSMCAApplicable } from 'utilities/vehicle';

export const VehicleDocumentUpload = ({ vehicle, documents, category = [] }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedRequiredCategory, setSelectedRequiredCategory] = useState(null);
  const [selectedAddtionalCategory, setSelectedAdditionalCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const selectADocumentRef = useRef();
  const [showConfirm, setShowConfirm] = useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState();

  const { canvasRef } = useRenderPdfToCanvas({
    file: selectedFile,
    scale: 1,
  });

  const { mutateAsync: createDocumentCategory, isLoading: isCreatingCategory } =
    useCreateDocumentCategory();
  const { mutateAsync: deleteDocumentCategory } = useDeleteDocumentCategory();
  const { mutateAsync: uploadVehicleDocument } = useUploadVehicleDocument();

  const requiredCatergoryOptions = useMemo(() => {
    return category
      .filter((c) => (c.isNHTSARequired && c.canDelete) || (DOCUMENT_CATEGORIES_USMCA.includes(c.name) && isUSMCAApplicable(vehicle.vin,vehicle.year)))
      .filter((c) => !documents.some((d) => d.category.id === c.id))
      .map((item) => ({
        label: item.name,
        value: item.id,
        id: item.id,
        canDelete: item.canDelete,
      }));
  }, [category, documents]);

  const additionalCategoryOptions = useMemo(() => {
    return category
      .filter((c) => !c.isNHTSARequired && c.canDelete)
      .filter((c) => !documents.some((d) => d.category.id === c.id))
      .map((item) => ({
        label: item.name,
        value: item.id,
        id: item.id,
        canDelete: item.canDelete,
      }));
  }, [category, documents]);

  const categoryNameToBeDeleted = useMemo(() => {
    return category.find((c) => c.id === categoryIdToDelete)?.name;
  }, [category, categoryIdToDelete]);

  const onDrop = (files) => {
    if (files[0]?.type === 'application/pdf') {
      setSelectedFile(files[0]);
    } else {
      Notification(NOTIFICATION_TYPE.DANGER, 'Please select a pdf file');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const removeFile = (event) => {
    event.stopPropagation();
    setSelectedFile(null);
  };

  const onFileUpload = async (e) => {
    e.stopPropagation();
    if (!selectedFile) {
      return selectADocumentRef.current.click();
    }
    let selectedCategory;
    if (selectedRequiredCategory) {
      selectedCategory = selectedRequiredCategory;
    } else if (selectedAddtionalCategory) {
      selectedCategory = selectedAddtionalCategory;
    }

    if (!selectedCategory) {
      return;
    }
    setLoading(true);
    const fd = new FormData();
    fd.append('vehicleId', vehicle.id);
    fd.append('file', selectedFile);
    fd.append('categoryId', selectedCategory.value);
    try {
      await uploadVehicleDocument(fd);

      setSelectedRequiredCategory(null);
      setSelectedAdditionalCategory(null);
      setSelectedFile(null);
      setLoading(false);
      Notification(NOTIFICATION_TYPE.SUCCESS, 'Document uploaded successfully');
    } catch (err) {
      setLoading(false);
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    }
  };

  const handleCreateCategory = async (data) => {
    try {
      await createDocumentCategory(data);
      Notification(NOTIFICATION_TYPE.SUCCESS, `Category "${data.name}" created successfully`);
    } catch (err) {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteDocumentCategory(categoryIdToDelete);

      if (categoryIdToDelete === selectedRequiredCategory?.id) {
        setSelectedRequiredCategory(null);
      } else if (categoryIdToDelete === selectedAddtionalCategory?.id) {
        setSelectedAdditionalCategory(null);
      }

      Notification(
        NOTIFICATION_TYPE.SUCCESS,
        `Category "${categoryNameToBeDeleted}" has been deleted successfully`
      );
    } catch (err) {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    }
  };

  return (
    <>
      <div className="document-upload-form">
        <h3 className="mb-4">Additional Documents Upload</h3>
        <div className="d-flex mb-2 gap-1">
          <CustomSelect
            name="category"
            id="required-category"
            label="Required Documents"
            placeholder="Select a category or type to create a new one"
            options={requiredCatergoryOptions}
            formatOptionLabel={(option, metaObj) => (
              <div className="d-flex justify-content-between align-items-center">
                <span>{option.label}</span>
                {!!option.canDelete && metaObj.context === 'menu' && (
                  <IconButton
                    icon={<Trash />}
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowConfirm(true);
                      setCategoryIdToDelete(option.id);
                    }}
                  />
                )}
              </div>
            )}
            isLoading={isCreatingCategory}
            value={selectedRequiredCategory}
            canCreateable={true}
            onCreate={(value) =>
              handleCreateCategory({
                name: value,
                isNHTSARequired: true,
                vehicleId: vehicle.id,
              })
            }
            onChange={(item) => {
              setSelectedRequiredCategory(item);
              setSelectedAdditionalCategory(null);
            }}
          />
          <CustomSelect
            name="category"
            id="additional-category"
            label="Additional Documents"
            placeholder="Select a category or type to create a new one"
            options={additionalCategoryOptions}
            formatOptionLabel={(option, metaObj) => (
              <div className="d-flex justify-content-between align-items-center">
                <span>{option.label}</span>
                {!!option.canDelete && metaObj.context === 'menu' && (
                  <IconButton
                    icon={<Trash />}
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowConfirm(true);
                      setCategoryIdToDelete(option.id);
                    }}
                  />
                )}
              </div>
            )}
            isLoading={isCreatingCategory}
            value={selectedAddtionalCategory}
            canCreateable={true}
            onCreate={(value) =>
              handleCreateCategory({
                name: value,
                isNHTSARequired: false,
                vehicleId: vehicle.id,
              })
            }
            onChange={(item) => {
              setSelectedAdditionalCategory(item);
              setSelectedRequiredCategory(null);
            }}
          />
        </div>
        <div className="mb-4">
          <div className="drop-box" {...getRootProps()}>
            <Button
              type="button"
              loading={loading}
              className={classnames({
                'my-3': selectedFile,
              })}
              disabled={
                selectedFile &&
                ((!selectedRequiredCategory && !selectedAddtionalCategory) || loading)
              }
              onClick={onFileUpload}
            >
              {!selectedFile
                ? 'Select a Document'
                : !selectedRequiredCategory && !selectedAddtionalCategory
                ? 'Select a category'
                : loading
                ? 'Uploading'
                : 'Upload'}
            </Button>
            <div ref={selectADocumentRef}>
              <input {...getInputProps()} accept="application/pdf" />
            </div>
            {selectedFile ? (
              <>
                <canvas ref={canvasRef} style={{ maxWidth: '300px' }} />
                <div className="remove-btn" onClick={removeFile}>
                  <Trash className="remove-icon" />
                </div>
              </>
            ) : (
              <>
                <span className="text-md text-grey mb-0 mt-1">or</span>
                <span className="text-md text-grey mb-0">Drag or Drop Document</span>
                <p className="text-sm text-grey-dark mb-0">PDF</p>
              </>
            )}
          </div>
        </div>
      </div>
      {showConfirm && (
        <ConfirmModal
          show={showConfirm}
          handleClose={() => setShowConfirm(false)}
          title="Delete photo category"
          content={`You're about to delete the document category "${categoryNameToBeDeleted}". Do you want to proceed?`}
          onConfirm={handleConfirmDelete}
        />
      )}
    </>
  );
};
